import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const LessonDropDown = (props) => {
  const { lesson } = props;
  const [listOpen, setListOpen] = useState(false);

  const handleClick = () => {
    setListOpen(!listOpen);
  };

  const {
    body,
    frontmatter: { title }
  } = lesson.node;

  return (
    <Styled.ContentSection>
      <Styled.ContentHeader>
        <Styled.ContentTitle>{title}</Styled.ContentTitle>
        <Styled.Cursor data-menu onClick={handleClick}>
          {listOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Hide"
              className="icon icon-tabler icon-tabler-chevron-up"
              width={28}
              height={28}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#718096"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 15 12 9 18 15" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Show"
              className="icon icon-tabler icon-tabler-chevron-down"
              width={28}
              height={28}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#718096"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 9 12 15 18 9" />
            </svg>
          )}
        </Styled.Cursor>
      </Styled.ContentHeader>
      {listOpen ? (
        <Styled.List>
          <Styled.ListItem>
            <FormatHtml content={body} />
          </Styled.ListItem>
        </Styled.List>
      ) : (
        <Styled.List hidden>
          <Styled.ListItem>
            <FormatHtml content={body} />
          </Styled.ListItem>
        </Styled.List>
      )}
    </Styled.ContentSection>
  );
};

const HomeTOCNew = () => {
  const { mdx, allMdx } = useStaticQuery(graphql`
    query {
      mdx(frontmatter: { category: { eq: "toc section new" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMdx(
        filter: { frontmatter: { category: { eq: "toc content" } } }
        sort: { fields: frontmatter___sequence, order: ASC }
      ) {
        edges {
          node {
            id
            body
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = mdx.frontmatter;
  const lessons = allMdx.edges;

  return (
    <Styled.HomeTOCNewContainer>
      <Styled.Container>
        <Styled.Box>
          <Styled.TitleSection>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Subtitle>{subtitle}</Styled.Subtitle>
          </Styled.TitleSection>
          {lessons.map((lesson) => {
            const { id } = lesson.node;
            return <LessonDropDown lesson={lesson} key={id} />;
          })}
        </Styled.Box>
      </Styled.Container>
    </Styled.HomeTOCNewContainer>
  );
};

export default HomeTOCNew;
