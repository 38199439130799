import styled from 'styled-components';
import tw from 'twin.macro';

export const HomeTOCNewContainer = styled.div`
  ${tw`flex flex-wrap mx-auto py-12 bg-extended-gray-2`};
`;

export const Container = styled.div`
  ${tw`container mx-auto p-8`}
`;

export const Box = styled.div`
  ${tw`max-w-screen-lg mx-auto`}
`;

export const TitleSection = styled.div`
  ${tw`pb-12`}
`;

export const Title = styled.h1`
  ${tw`xl:text-4xl text-3xl font-extrabold text-gray-900 mb-4`}
`;

export const Subtitle = styled.h4`
  ${tw`text-gray-600 text-lg leading-tight mb-4`}
`;

export const ContentSection = styled.div`
  ${tw`bg-white shadow border rounded mx-auto mb-4 xl:px-8 lg:px-8 md:px-8 sm:px-8 px-4`}
`;

export const ContentHeader = styled.div`
  ${tw`flex py-6 justify-between items-center border-b border-gray-200`}
`;

export const ContentTitle = styled.h1`
  ${tw`text-xl text-gray-800 font-bold`}
`;

export const Cursor = styled.div`
  ${tw`cursor-pointer`}
`;

export const List = styled.ul`
  ${({ hidden }) => hidden && tw`hidden`}
`;

export const ListItem = styled.li`
  ${tw`px-4 py-2`}
`;
