import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import Icon from 'components/ui/Icon';

import * as Styled from './styles';

const Banner = ({ title, subtitle, content, linkTo, linkText }) => (
  <Styled.Banner>
    <Container>
      <TitleSection title={title} subtitle={subtitle} />
      <Styled.Content>{content}</Styled.Content>
      <Styled.ButtonContainer>
        <Link to={linkTo}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
            <Styled.Button>
              {linkText}&nbsp;&nbsp;&nbsp;
              <Icon icon="arrow-right" />
            </Styled.Button>
          </motion.div>
        </Link>
      </Styled.ButtonContainer>
    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
