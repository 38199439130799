import styled from 'styled-components';
import tw from 'twin.macro';

export const Banner = styled.section`
  ${tw`flex flex-col w-full bg-white text-gray-900 text-center leading-loose my-16`};
`;

export const Content = styled.div`
  ${tw`flex mx-auto w-11/12 text-xl text-center text-gray-600 mb-8 items-center justify-center`};
`;

export const ButtonContainer = styled.div`
  ${tw`w-full text-center`};
`;

export const Button = styled.button`
  outline: none !important;
  ${tw`py-2 px-8 bg-gray-900 shadow-lg text-white hover:border-white hover:bg-purple-600`};
`;
