import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBanner from 'components/HeroBanner';
import EbookDownload from 'components/EbookDownload';
import HomeTOCNew from 'components/HomeTOCNew';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HeroBanner />
      <EbookDownload />
      <HomeTOCNew />
    </Layout>
  );
};

export default IndexPage;
