import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'components/ui/Banner';

const HeroBanner = () => {
  const { mdx } = useStaticQuery(graphql`
    query {
      mdx(frontmatter: { category: { eq: "hero section" } }) {
        frontmatter {
          boldTitle
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

  const heroBanner = mdx.frontmatter;

  return (
    <Banner
      boldTitle={heroBanner.boldTitle}
      title={heroBanner.title}
      content={heroBanner.content}
      linkTo={heroBanner.linkTo}
      linkText={heroBanner.linkText}
    />
  );
};

export default HeroBanner;
