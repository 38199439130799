import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Styled from './styles';

const EbookDownload = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const { mdx } = useStaticQuery(graphql`
    query {
      mdx(frontmatter: { category: { eq: "ebook download section" } }) {
        frontmatter {
          title
          subtitle
          emailPlaceholder
          submitPlaceholder
        }
      }
    }
  `);

  const ebookDownload = mdx.frontmatter;

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = JSON.stringify({ email_address: email });
    try {
      const res = await fetch(process.env.GATSBY_DOWNLOAD_URL, {
        method: 'post',
        body: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setEmail('');
      const json = await res.json();
      if (json.status === 'success') {
        setResult(json.status);
        setIsSuccess(true);
        return;
      } else {
        setResult(json);
        setIsSuccess(false);
      }
    } catch (err) {
      setResult(err.message);
      setIsSuccess(false);
    }
  };

  return (
    <Styled.Newsletter>
      <Styled.Container>
        <Styled.Title>{ebookDownload.subtitle}</Styled.Title>
        {result ? (
          isSuccess ? (
            <Styled.Alert>
              Silahkan cek inbox kamu untuk langkah selanjutnya.
            </Styled.Alert>
          ) : (
            <Styled.Alert danger>Terjadi Kesalahan. Silahkan coba lagi</Styled.Alert>
          )
        ) : (
          <></>
        )}

        <Styled.Form onSubmit={handleSubmit}>
          <Styled.Input type="email" name="email" placeholder={ebookDownload.emailPlaceholder} onChange={handleChange} />
          <Styled.Button type="submit">{ebookDownload.submitPlaceholder}</Styled.Button>
        </Styled.Form>
      </Styled.Container>
    </Styled.Newsletter>
  );
};

export default EbookDownload;
